import styles from '@hapstack/tailwind-config/tailwind.css?url'
import {
  iconSpriteHref,
  toast as setToast,
  Toaster,
  TooltipProvider,
} from '@hapstack/ui'
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { useEffect } from 'react'

import { env } from './utils/env.server'
import { getToast } from './utils/toast.server'

export const meta: MetaFunction<typeof loader> = ({ data }) => [
  { title: `${data?.ENV.CONSTANTS.APP_NAME} Extension` },
  {
    name: 'viewport',
    content: 'width=device-width,initial-scale=1,minimum-scale=1',
  },
  { name: 'charset', content: 'utf-8' },
]

export const links = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'preload', href: iconSpriteHref, as: 'image', type: 'image/svg+xml' },
]

export async function loader({ request }: LoaderFunctionArgs) {
  const { toast, headers } = await getToast(request)

  return json(
    {
      toast,
      ENV: {
        CONSTANTS: env.CONSTANTS,
      },
    },
    { headers }
  )
}

function App() {
  const { toast } = useLoaderData<typeof loader>()

  useEffect(() => {
    if (!toast) return

    const { variant, title, ...options } = toast

    switch (variant) {
      case 'success':
        setToast.success(title, options)
        break
      case 'error':
        setToast.error(title, options)
        break
      default:
        setToast.info(title, options)
    }
  }, [toast])

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="font-sans text-primary antialiased">
        <TooltipProvider>
          <Outlet />
        </TooltipProvider>

        <ScrollRestoration />
        <Scripts />
        <Toaster />
      </body>
    </html>
  )
}

export default withSentry(App, { wrapWithErrorBoundary: true })

export function ErrorBoundary() {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  return (
    <html>
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>

      <body>
        <main className="container max-w-2xl p-4 md:p-10">
          Whoops! Something went wrong.
        </main>

        <Scripts />
      </body>
    </html>
  )
}
